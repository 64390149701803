<template>
  <div class="wordscard-container layout-border">
    <WordCards
      :wordCardList="wordCardList"
      @changeDisplay="handleChangeDisplay"
    />
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: "redColor",
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          pinyin: "tā ",
          hanzi: "他",
          english: "he",
          image: require(`@/assets/img/12-Vocabulary/lesson-17-ta-he-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          pinyin: "tā",
          hanzi: "她",
          english: "she",
          image: require(`@/assets/img/12-Vocabulary/lesson-17-ta-she-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "five",
          isShowImg: "true",
          currentNum: 5,
          pinyin: "shéi",
          hanzi: "谁",
          english: "who",
          image: require(`@/assets/img/12-Vocabulary/lesson-17-shei-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "two",
          isShowImg: "true",
          currentNum:2,
          pinyin: "jiào",
          hanzi: "叫",
          english: "to be called",
          image: require(`@/assets/img/12-Vocabulary/lesson-17-jiao-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "two",
          isShowImg: "true",
          currentNum:4,
          pinyin: "Tā / Tā shì shéi?",
          hanzi: "他/她是谁？",
          english: "Who is he/she?",
          image: require(`@/assets/img/12-Vocabulary/lesson-17-ta-shi-shei-bubble.svg`),
          blue: true,
          longtext: true,
        },
      ],
    };
  },
  methods: {
    handleChangeDisplay(index, isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg;
    },
  },
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
